import React, { useState } from 'react'
import './Thanks.scss'
import JotformEmbed from 'react-jotform-embed';

import unlock from '../../Images/unlock.png'
import Apply from '../../Images/Apply.png'
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import Found from '../../Images/Found.png'
import BUSINESS5 from '../../Images/BUSINESS5.png'
import check from '../../Images/check.png'

import { Helmet } from "react-helmet";
// var __html = require('./ApplicationsJS.html');

export default function Thanks({ navigateTo }) {
    const styles = {} // if you want to add some custom CSS
    const URL = "https://form.jotform.com/jsform/231987996785081" //embed URL

    return (
        <div className="Applications">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Apply | MCA & Co, Merchant Cash Advance</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.mcaandco.com/applications" data-react-helmet="true" />
                <meta name="description" content="On our cash advance application page, access a streamlined process for quick and efficient funding tailored to your business requirements." data-react-helmet="true" />
                <meta property="og:title" content="Apply | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta property="og:description" content="On our cash advance application page, access a streamlined process for quick and efficient funding tailored to your business requirements." data-react-helmet="true" />
                <meta property="og:site_name" content="Apply | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true"></meta>
            </Helmet>
            <div className="ApplicationsHeader" id='top'>
                <div className='ApplicationsHeader2'>
                    <img src={unlock} alt="" />
                    <h3>Your information is encrypted</h3>
                </div>
            </div>
            <div className="ThanksBox">
                <img src={check} alt="" />
                <h1>Thank You !</h1>
                <h3>Your application has been completed correctly</h3>
                <h4>What next ?</h4>
                <h2>- Our dedicated team is actively working on your case, carefully reviewing your application and documents.</h2>
                <h2>- A highly skilled funding specialist will personally connect with you. They will guide you through the various offers available, tailored specifically to meet the unique needs of your business.</h2>
            </div>
            <div className="ApplicationsWar">
                <img src={unlock} alt="" />
                <h2>- This will NOT affect your credit score</h2>
                <h3>- We keep your data safe and will not share your information without your approval</h3>
            </div>
            <div class="ApplicationsReview">
                <div class="ApplicationsReviewBox">
                    <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_techcrunch.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_techcrunch.svg?v=2.5" alt="Tech Crunch" />
                    <img class="lazyload-mobile" src="https://www.freepnglogos.com/uploads/forbes-logo-png/b-c-forbes-business-grey-logo-17.png" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_forbes.svg?v=2.5" alt="Forbes" />
                    <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_inc.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_inc.svg?v=2.5" alt="Inc" />
                    <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_business_insider.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_business_insider.svg?v=2.5" alt="Smart Company" />
                    <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_crowdfund_insider.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_crowdfund_insider.svg?v=2.5" alt="Crowd Fund Insider" />
                    <img class="lazyload-mobile" src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_debanked.svg?v=2.5" data-src="https://dav3uwijswi8g.cloudfront.net/become/homepage/img/news_logo_debanked.svg?v=2.5" alt="DeBanked" />
                </div>
                <h1>Compare funding offers!</h1>
                <h2>Discover all of your funding offers and secure the best loan for your business.</h2>
            </div>
            <div className="HowItWork">
                <h1>How it work</h1>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>01</h2>
                        <img src={Apply} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Apply</h3>
                        <h5>Discover a new level of convenience when applying with us! Experience the simplest and most streamlined application process ever devised. Gone are the days of complicated paperwork and lengthy procedures. With just a few clicks, you'll be well on your way to seizing exciting opportunities.</h5>
                    </div>

                </div>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>02</h2>
                        <img src={Review} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Review & Approve</h3>
                        <h5>After the thorough underwriting process is completed, a highly skilled funding specialist will personally connect with you. They will guide you through the various offers available, tailored specifically to meet the unique needs of your business. With their expertise and deep understanding of the industry, they will assist you in selecting the perfect option that aligns with your goals and aspirations.</h5>
                    </div>
                </div>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>03</h2>
                        <img src={Found} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Get Funded</h3>
                        <h5>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement. This contract will be tailored specifically to your chosen offer, ensuring complete transparency and clarity. You can review the terms at your convenience and, once satisfied, sign the contract electronically for convenience and efficiency.</h5>
                    </div>
                </div>
            </div>
            <div className="ApplBUSINESs">
                <img src={BUSINESS5} alt="" />
                <h2 onClick={()=>window.scrollTo(0, 0)}>Start Your Application <img src={next} alt="" /></h2>
            </div>

        </div>
    )
}


