import React from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import tiktok from '../../Images/tiktok.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import './Menu.scss';
import $ from 'jquery';

export default function Menu({ navigateTo, setShowMenu }) {

    return (
        <div className="Menu">
            <div className="MenuHeader">
                <img onClick={() => {                    
                    setShowMenu(false)
                }} src={closeMenu} alt="" />
            </div>
            <div className="MenuMain">
                <h1 onClick={() => {                    
                    navigateTo("")
                }}>Home</h1>
                <h1 onClick={() => {                    
                    navigateTo("applications")
                }}>Apply</h1>
                <h1 onClick={() => {                    
                    navigateTo("about")
                }}>About </h1>
                <h1 onClick={() => {                    
                    navigateTo("support")
                }}>FAQs </h1>
                <h1 onClick={() => {                    
                    navigateTo("contact")
                }}>Contact</h1>
                <h2 onClick={()=>navigateTo("applications")}>Start Your Application <img src={next} alt="" /></h2>
            </div>
            {/* <div className="MenuSocialMedia">
                <img src={tiktok} alt="" />
                <img src={facebook} alt="" />
                <img src={instagram} alt="" />
            </div> */}
            <div className="MenuFooter">
                <h5>Copyright © 2023 MCA & Co. All rights reserved.</h5>
            </div>
        </div>
    )
}
