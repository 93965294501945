import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Apply from '../../Images/Apply.png'
import Review from '../../Images/Review.png'
import Found from '../../Images/Found.png'
import StartNow from '../../Images/StartNow.png'
import Banner12 from '../../Images/Banner12.png'


import customerreview from '../../Images/001-customer-review.png'
import controversial from '../../Images/002-controversial.png'
import protect from '../../Images/003-protect.png'
import invest from '../../Images/004-invest.png'

export default function HomePage({ navigateTo }) {
    const [showOne, setshowOne] = useState(true);
    const [showTwo, setshowTwo] = useState(false);
    const [showThree, setshowThree] = useState(false);

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>MCA & Co, Merchant Cash Advance</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.mcaandco.com/" data-react-helmet="true" />
                <meta name="description" content="Our cash advance offers swift access to tailored funding solutions, empowering your business's growth and financial success. Explore opportunities today!" data-react-helmet="true" />
                <meta property="og:title" content="MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta property="og:description" content="Our cash advance offers swift access to tailored funding solutions, empowering your business's growth and financial success. Explore opportunities today!" data-react-helmet="true" />
                <meta property="og:site_name" content="MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true"></meta>
            </Helmet>

            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerBubble">
                    <div className="MainBannerBubbleTable"></div>
                    <div className="MainBannerBubbleBox"></div>
                    <div className="MainBannerBubbleBox2"></div>
                    <div className="MainBannerBubbleBox3"></div>

                </div>
                <div className="HomePageText">
                    <h1>Funding to fuel your business</h1>
                    <h2>Compare multiple loan options at once and choose <br /> the best fit for your needs with confidence.</h2>
                    <button onClick={() => navigateTo('about')}>Learn More</button>
                </div>
                <div className="HomePagePhone">
                    <div className="HomePagePhoneLine"></div>
                    <div className="HomePagePhoneInfo">
                        <h3>Best Small Business Loans</h3>
                        <h4>$5K - $1M</h4>
                        <h5>Next Day Funded</h5>
                        <button>Get Started</button>
                    </div>
                    <div className="HomePagePhoneCircle"></div>
                </div>
            </div>
            <div className="HowItWork">
                <h1>How it work</h1>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>01</h2>
                        <img src={Apply} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Apply</h3>
                        <h5>Discover a new level of convenience when applying with us! Experience the simplest and most streamlined application process ever devised. Gone are the days of complicated paperwork and lengthy procedures. With just a few clicks, you'll be well on your way to seizing exciting opportunities.</h5>
                    </div>

                </div>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>02</h2>
                        <img src={Review} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Review & Approve</h3>
                        <h5>After the thorough underwriting process is completed, a highly skilled funding specialist will personally connect with you. They will guide you through the various offers available, tailored specifically to meet the unique needs of your business. With their expertise and deep understanding of the industry, they will assist you in selecting the perfect option that aligns with your goals and aspirations.</h5>
                    </div>
                </div>
                <div className="HowItWorkLine"></div>
                <div className="numberOne">
                    <div className="numberOneLeft">
                        <h2>03</h2>
                        <img src={Found} alt="" />
                    </div>
                    <div className="numberOneRight">
                        <h3>Get Funded</h3>
                        <h5>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement. This contract will be tailored specifically to your chosen offer, ensuring complete transparency and clarity. You can review the terms at your convenience and, once satisfied, sign the contract electronically for convenience and efficiency.</h5>
                    </div>
                </div>
            </div>
            <div className="GetStarted">
                <div className="GetStartedLeft">
                    <h2>Apply online</h2>
                    <h3>Start Now</h3>
                    <h4>Apply online to have one of our financial specialists guide you through the process.</h4>
                    <button>Apply Now</button>
                </div>
                <div className="GetStartedRight">
                    <h1>SECURE</h1>
                    <img src={StartNow} alt="" />
                </div>
            </div>
            <div className="ToolsToStart">
                <div className="ToolsToStartTop">
                    <div className="ToolsToStartTopLeft">
                        <div className="ToolsToStartTopBox">
                            <div className="ToolsToStartTopBoxLeft">
                                <img src={customerreview} alt="" />
                            </div>
                            <div className="ToolsToStartTopBoxRight">
                                <h3>Secure the financial</h3>
                                <h4>Secure the financial future of your business with a tailored business loan, designed to fuel growth and expansion</h4>
                                <button>Compare Rates</button>
                            </div>
                        </div>
                        <div className="ToolsToStartTopBox">
                            <div className="ToolsToStartTopBoxLeft">
                                <img src={controversial} alt="" />
                            </div>
                            <div className="ToolsToStartTopBoxRight">
                                <h3>Competitive Interest</h3>
                                <h4>Our competitive interest rates and favorable repayment terms make our business loans the ideal choice for entrepreneurs looking to achieve their goals.</h4>
                                <button>Compare Rates</button>
                            </div>
                        </div>
                    </div>
                    <div className="ToolsToStartTopRight">
                        <div className="ToolsToStartTopBox">
                            <div className="ToolsToStartTopBoxLeft">
                                <img src={protect} alt="" />
                            </div>
                            <div className="ToolsToStartTopBoxRight">
                                <h3>Experienced Financial</h3>
                                <h4>Our experienced financial advisors are ready to guide you through the loan options, ensuring you make the best decision for your business's future.</h4>
                                <button>Compare Rates</button>
                            </div>
                        </div>
                        <div className="ToolsToStartTopBox">
                            <div className="ToolsToStartTopBoxLeft">
                                <img src={invest} alt="" />
                            </div>
                            <div className="ToolsToStartTopBoxRight">
                                <h3>Invest in your business</h3>
                                <h4>Invest in your business's potential and watch it flourish - our business loans offer the boost you need to make your entrepreneurial dreams a reality.</h4>
                                <button>Compare Rates</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="ToolsToStartBottom">
                    <img src={Banner12} alt="" />
                </div>
            </div>

        </div>
    )
}

