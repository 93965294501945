import React, { useState } from 'react'
import { useEffect } from 'react';
import './About.scss'
import { Helmet } from "react-helmet";

import $ from 'jquery';
// images
import next from '../../Images/next.png'
import cash from '../../Images/003-cash.png'
import discount from '../../Images/002-discount.png'
import convenience from '../../Images/001-convenience.png'

export default function About({ navigateTo }) {

    return (
        <div className="About">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>About Us | MCA & Co, Merchant Cash Advance</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.mcaandco.com/about" data-react-helmet="true" />
                <meta name="description" content="Welcome to our cash advance about page. Discover how we provide fast, reliable, and tailored funding solutions for businesses to thrive." data-react-helmet="true" />
                <meta property="og:title" content="About Us | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta property="og:description" content="Welcome to our cash advance about page. Discover how we provide fast, reliable, and tailored funding solutions for businesses to thrive." data-react-helmet="true" />
                <meta property="og:site_name" content="About Us | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true"></meta>
            </Helmet>
            <div className="AboutHeader">
                <h4>Flexibility is key – our cash advance solutions cater to your business needs.</h4>
                <h1>Our mission</h1>
                <h2><h3>Merchant Cash Advance</h3>At MCA & Co, we live up to our name – offering transparency and simplicity in accessing the most extensive online lending network available – period! Our founding team, comprising entrepreneurs, bankers, and investors, recognizes the paramount importance of finding a dependable lender – someone who stands by you through thick and thin. With you, the business owner, at the forefront of our minds, we have tailored our expertise to cater to your needs.</h2>
            </div>

            <div className="AboutBonus">
                <div className="AboutBonusHeader">
                    <h1>Advantages</h1>
                </div>
                <div className="AboutBonusList">
                    <div className="AboutBonusBox">
                        <img src={cash} alt="" />
                        <h3>Funding up to</h3>
                        <h4>$1,000,000</h4>
                    </div>
                    <div className="AboutBonusBox">
                        <img src={discount} alt="" />
                        <h3>Rates as low as</h3>
                        <h4>9%</h4>
                    </div>
                    <div className="AboutBonusBox">
                        <img src={convenience} alt="" />
                        <h3>Approvals as fast as</h3>
                        <h4>5 Minutes</h4>
                    </div>
                </div>
            </div>
            <div className="AboutStart">
                <h1>Take action now, discover your eligibility for our offers today.</h1>
                <h3>Applying Will Not Affect Your Credit Score</h3>
                <h2 onClick={() => navigateTo("applications")}>Apply Now <img src={next} alt="" /></h2>
            </div>
        </div>
    )
}

