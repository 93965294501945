import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import About from '../Components/About/About'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from '../Components/TermsConditions/TermsConditions'
import Support from '../Components/Support/Support'
import Applications from '../Components/Applications/Applications'
import Thanks from '../Components/Thanks/Thanks'
import Applications2 from '../Components/Applications2/Applications2'


export default function Main({navigateTo }) {
    
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomePage navigateTo={navigateTo} />} />                
                <Route path="/contact" element={<Contact  />} />
                <Route path="/applications" element={<Applications  navigateTo={navigateTo}/>} />
                <Route path="/applications2" element={<Applications2  navigateTo={navigateTo}/>} />
                <Route path="/about" element={<About  />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy  navigateTo={navigateTo} />} />
                <Route path="/termsconditions" element={<TermsConditions  navigateTo={navigateTo} />} />
                <Route path="/support" element={<Support  navigateTo={navigateTo} />} />
                <Route path="/thanks" element={<Thanks  navigateTo={navigateTo} />} />
            </Routes>
        </div>
    )
}
