import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';

// import { useLocation } from "react-router-dom";

export default function Footer({ navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
             <div className="JoinUs">
                <div className="JoinUsLeft">
                    <div className="HomePagePhone">
                        <div className="HomePagePhoneLine"></div>
                        <div className="HomePagePhoneInfo">
                            <h3>Up to 5 minutes confirmation</h3>
                            <h4>5 Minutes</h4>
                            <h5>Next Day Money</h5>
                        </div>
                        <div className="HomePagePhoneCircle"></div>
                    </div>

                </div>
                <div className="JoinUsRight">
                    <h1>Join Together With Flexible Funding Options</h1>
                    <h2>Discover the freedom to tailor your financial solutions with our diverse range of flexible funding options to suit your unique business needs.</h2>
                    <button>Start Today</button>
                </div>
            </div>
            <div className="FooterBorder"></div>
            <div className="FooterTop">
                <div className="LogoDiv">
                    <div className='Header1' onClick={() => navigateTo("")}>
                        <h1>MCA & Co</h1>
                        <h2>Merchant Cash Advance</h2>
                    </div>
                    <h3>Secure the financial support your business needs with our flexible and competitive business loan options.</h3>
                </div>
                <div className="SiteMapDiv">
                    <h2 onClick={() => Navigate("/products")}>Home</h2>
                    <h2 onClick={() => Navigate("/products")}>Apply</h2>
                    <h2 onClick={() => Navigate("/about")}>About</h2>
                    <h2 onClick={() => Navigate("/support")}>Support</h2>
                    <h2 onClick={() => Navigate("/contact")}>Contact</h2>
                </div>
            </div>
            <div className="FooterBottom">
                <div className="FooterBottomTop">
                    <h3>Copyright © 2023 Genesis Advance. All rights reserved.</h3>
                    <div>
                        <h4 onClick={() => navigateTo('privacypolicy')}>Privacy</h4>
                        <h4 onClick={() => navigateTo('termsconditions')}>Terms</h4>
                        <h4 onClick={() => navigateTo('termsconditions')}>Cookies</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
