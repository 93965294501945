import './App.scss';
import Header from '../Tools/Header/Header';
import Footer from '../Tools/Footer/Footer';
import Menu from '../Tools/Menu/Menu';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Main from './Main';

function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [showVerify, setShowVerify] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const navigateTo = (page) => {
    navigate('/' + page)
    setShowMenu(false)

  }


  useEffect(() => {
    console.log(location)

    if(showVerify == true){
      document.body.style.overflow = "hidden"
      document.body.style.overflow = "scroll"
    }else{
      
      document.body.style.overflow = "scroll"
    }

 
  }, [showVerify]);

  return (
    <div className="App" style={false ? { "document.html.style.overflow": "hidden" } : null}>
      {showMenu ? <Menu navigateTo={navigateTo} setShowMenu={setShowMenu} /> : null}
      {location.pathname == "/applications" || location.pathname == "/applications2" ? null : <Header navigateTo={navigateTo} setShowMenu={setShowMenu} />}
      <Main navigateTo={navigateTo}/>
      {location.pathname == "/applications" || location.pathname == "/applications2" ? null : <Footer navigateTo={navigateTo} />}
    </div>
  );
}

export default App;
