import React from 'react'
import leftMenu from '../../Images/leftMenu.png'
import next from '../../Images/next.png'
import './Header.scss';
import $ from 'jquery';
import { useLocation } from "react-router-dom";

export default function Header({ setShowMenu, navigateTo }) {
    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">
            <div className='Header1' onClick={() => navigateTo("")}>
                <h1>MCA & Co</h1>
                <h2>Merchant Cash Advance</h2>
            </div>
            <div className='Header2'>
                <h2 onClick={() => navigateTo("applications")}>Apply <img src={next} alt="" /></h2>
                <img onClick={() => {
                    setShowMenu(true)
                }} src={leftMenu} alt="" />

            </div>
        </div>
    )
}
