import React, { useState } from 'react'
import './Support.scss'
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

import test2 from '../../Images/test2.jpg'
import moneyBag from '../../Images/money-bag.png'

export default function Support({ navigateTo }) {
    const data = {
        title: "",
        rows: [
            {
                title: "What is a merchant cash advance (MCA)?",
                content: `A merchant cash advance is a form of financing where a business receives a lump sum of cash in exchange for a percentage of its future credit card sales.`,
            },
            {
                title: "How does a merchant cash advance work?",
                content: "Upon approval, the MCA provider advances a lump sum, and the business repays the amount plus a fee by remitting a percentage of daily credit card sales until the agreed-upon amount is paid off.",
            },
            {
                title: "What are the typical repayment terms for an MCA?",
                content: `Repayment terms are often based on a fixed percentage of daily or wekly credit card sales until the advance, plus the fee, is fully repaid, which means the time to repay can vary based on sales volume.`,
            },
            {
                title: "What are the advantages of a merchant cash advance?",
                content: `MCA offers quick access to cash, no fixed monthly payments, and doesn't require collateral or a high credit score.`,
            },
            {
                title: "Are there any disadvantages to consider with an MCA?",
                content: `Merchant cash advances can have higher costs compared to traditional loans, making them less suitable for long-term financing.`,
            },
            {
                title: "How long does the application process usually take?",
                content: `The application process for an MCA is typically faster than traditional loans, often taking just a few days for approval.`,
            },
            {
                title: "Can businesses with bad credit qualify for an MCA?",
                content: `Yes, businesses with less-than-perfect credit may still qualify for an MCA since approval is based more on credit card sales history than credit scores.`,
            },
            {
                title: "Is there a maximum limit on the amount a business can receive with an MCA?",
                content: `es, MCA providers often set a cap on the maximum amount they are willing to advance, which varies based on the business's credit card sales volume.`,
            },
            {
                title: "Can a business apply for multiple merchant cash advances simultaneously?",
                content: `Some providers may allow businesses to have multiple MCAs, but it depends on the provider and the financial health of the business.`,
            },
            {
                title: "Are there industries that are ineligible for merchant cash advances?",
                content: `Certain high-risk industries may find it challenging to qualify for an MCA, as providers assess risk based on factors such as chargeback rates and industry stability.`,
            },
        ],
    };

    const styles = {
        bgColor: 'white',
        titleTextColor: '#002f68ae',
        titleTextSize: '18px',
        rowTitleColor: '#002f68',
        rowTitleTextSize: 'medium',
        rowContentColor: '#002f68df',
        rowContentTextSize: '15px',
        // rowContentTextWeight: '1px',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        // rowContentPaddingLeft: '50px',
        // rowContentPaddingRight: '150px',
        arrowColor: "#002f68",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    return (
        <div className="Support">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Support | MCA & Co, Merchant Cash Advance</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.mcaandco.com/support" data-react-helmet="true" />
                <meta name="description" content="Fast, reliable, and tailored funding solutions, empowering your business with the financial support it needs to succeed and flourish." data-react-helmet="true" />
                <meta property="og:title" content="Support | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta property="og:description" content="Fast, reliable, and tailored funding solutions, empowering your business with the financial support it needs to succeed and flourish." data-react-helmet="true" />
                <meta property="og:site_name" content="Support | MCA & Co, Merchant Cash Advance" data-react-helmet="true" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" data-react-helmet="true"></meta>
            </Helmet>

            <div className="SupportHeader">
                <h4>FEATURED: LOAN PROCESS</h4>
                <h1>Q: What is the expected timeframe for receiving the funds?</h1>
                <h2><h3>A:</h3>Our lending partners follow distinct approval processes, resulting in diverse funding timelines that influence when you'll receive the funds</h2>
            </div>
            <div className="SupportFaq">
                <div className="SupportFaqHeader">
                    <h1>FAQ</h1>
                </div>
                <div className="SupportFaqMain">
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </div>
            <div className="SupportFaqBanner">
                <div className="SupportFaqBannerTop">
                    <h1>The Story Behind Us</h1>
                </div>
                <div className="SupportFaqBannerBottom">
                    <div className="SupportFaqBannerInfo">
                        <h1>Quick, Accessible, Funding.</h1>
                        <h2>At MCA & Co, we specialize in providing hassle-free and flexible cash advance solutions to empower businesses with the financial support they need to thrive.</h2>
                        <h3 onClick={() => navigateTo('about')} >Learn More</h3>
                    </div>
                    <div className="SupportFaqBannerImages">
                        <img className='SupportFaqBannerImages1' src={moneyBag} alt="business loans, quick loan, low rates" />
                        <img className='SupportFaqBannerImages2' src={test2} alt="cash advance" />
                    </div>

                </div>
            </div>
        </div>
    )
}

