import React, { useState } from 'react'
import './TermsConditions.scss'
import { Helmet } from "react-helmet";

export default function TermsConditions({ navigateTo }) {

    return (
        <div className="TermsConditions">
            <script type="text/javascript" src="https://form.jotform.com/jsform/231987996785081"></script>
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Terms Conditions | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/termsconditions" data-react-helmet="true" />
                <meta name="description" content="Welcome to Thebumpvape.com! By visiting this websi`te, you agree to abide by the following terms and conditions. We kindly request that you carefully review each section. To fully understand BUMP's business practices and your obligations when conducting business with us, please take a moment to review our Privacy Policy." data-react-helmet="true" />
                <meta property="og:title" content="Terms Conditions | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Welcome to Thebumpvape.com! By visiting this websi`te, you agree to abide by the following terms and conditions. We kindly request that you carefully review each section. To fully understand BUMP's business practices and your obligations when conducting business with us, please take a moment to review our Privacy Policy." data-react-helmet="true" />
                <meta property="og:site_name" content="Terms Conditions | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Terms Conditions" data-react-helmet="true"></meta>
            </Helmet>
            
            <div className="TermsConditionsHeader">
                <h1>Terms Conditions</h1>
                <h2>Updated May 11, 2023</h2>
            </div>
            <div className="TermsConditionsMain">

                <h3>Effective Date: May 11, 2023</h3>
                <h4>
                    Welcome to Thebumpvape.com! By visiting this websi`te, you agree to abide by the following terms and conditions. We kindly request that you carefully review each section. To fully understand BUMP's business practices and your obligations when conducting business with us, please take a moment to review our Privacy Policy.
                    <br />
                    <br />
                    In the event that we make any changes to these terms and conditions, we will notify you by posting a notice on this site. It is your responsibility to accept the updated terms and conditions when placing subsequent orders.
                </h4>


                <h3>Electronic Communications</h3>
                <h4>
                    When you send emails to BUMP, you are engaging in electronic communication with us. This allows BUMP to respond to you in the same electronic format, either via email or by posting written responses on our website. By agreeing to these terms and conditions, we mutually consent that any contracts, agreements, disclosures, notices, or other communications provided to you by BUMP meet all legal notification requirements.
                    <br />
                    <br />
                    You acknowledge that BUMP may send you promotional emails. By accepting these terms and conditions, you give your consent to receive such emails. You have the option to unsubscribe from promotional emails at any time.
                </h4>

                <h3>Copyright</h3>
                <h4>
                    All content featured on this site, including text, graphics, photos, logos, button icons, images, audio clips, videos, digital downloads, and data compilations, is protected by international copyright laws. No part of the information on this website may be copied or reproduced in any form or by any means without prior written permission from BUMP. Users and visitors are strictly prohibited from modifying, distributing, publishing, transmitting, copying, or creating derivative works of any materials found on this site or any BUMP publication, whether for public, private, or commercial purposes.
                </h4>

                <h3>Trademarks</h3>
                <h4>
                    BUMP, as well as other Thebumpvape.com graphics, logos, page headers, button icons, scripts, and service names, are registered trademarks.
                </h4>

                <h3>Patents</h3>
                <h4>
                    Patents are currently pending for BUMP devices and their components.
                </h4>

                <h3>Product Descriptions</h3>
                <h4>
                    Thebumpvape.com strives to provide accurate product descriptions to the best of our ability.
                </h4>

                <h3>Product Variations</h3>
                <h4>
                    Please be aware that there may be slight variations in color and puff count among our products.
                </h4>

                <h3>Severability</h3>
                <h4>
                    In the event that any provision of these site terms is deemed unlawful, void, or unenforceable for any reason, such provision shall be considered severable from these terms and conditions. The invalidity or unenforceability of one provision shall not affect the validity and enforceability of the remaining provisions.
                </h4>
            </div>
        </div>
    )
}

